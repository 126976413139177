import React from "react";
import { graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic";
import SEO from "../components/seo";
import ProjetList from "../components/slices/ProjetList";
import ProjectSlider from "../components/slices/ProjectSlider";
// import ProjectScroller from "../components/slices/ProjectScroller";
import ImageTexte from "../components/slices/ImageTexte";
import clsx from "clsx";
import SlideContainer from "../components/slices/SlideContainer";

export const query = graphql`
  query PageBySlug($uid: String!, $lang: String!) {
    prismicPage(uid: { eq: $uid }, lang: { eq: $lang }) {
      data {
        meta_title
        meta_description {
          text
        }
        meta_image {
          url
        }
        home_page
        title {
          text
        }
        body {
          ... on PrismicPageBodyProjectSlider {
            slice_type
            items {
              project {
                document {
                  ... on PrismicProject {
                    type
                    uid
                    lang
                    data {
                      title {
                        text
                      }
                      texte {
                        raw
                      }
                      image_featured {
                        ...sharp
                      }
                      image_featured_mobile {
                        ...sharp
                      }
                      video {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyProjectList {
            slice_type
            items {
              project {
                document {
                  ... on PrismicProject {
                    type
                    uid
                    lang
                    data {
                      title {
                        text
                      }
                      texte {
                        raw
                      }
                      image_featured {
                        ...sharp
                      }
                      image_featured_mobile {
                        ...sharp
                      }
                      images {
                        image {
                          ...sharp
                        }
                        image2 {
                          ...sharp
                        }
                        video {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Page = ({ data }) => {
  const { meta_title, meta_description, meta_image, home_page, title, body } =
    data.prismicPage.data;
  // console.log(body);

  const slices = body.map((slice, i) => {
    // console.log(slice.slice_type);
    switch (slice.slice_type) {
      case "project_list":
        return <ProjetList key={i} input={slice} />;
      case "project_slider":
        return <ProjectSlider key={i} input={slice} />;
      case "image_texte":
        return <ImageTexte key={i} input={slice} />;
      default:
        return null;
    }
  });

  return (
    <div className='page'>
      <SEO
        pageTitle={meta_title ? meta_title : title.text}
        pageDescription={
          meta_description ? meta_description.text : "desc google here"
        }
        pageImage={
          meta_image
            ? meta_image.url
            : "https://images.prismic.io/sisto-studios/905ac41b-19ac-45f2-8da1-6235d04a746f_sisto-architecture-9.png"
        }
        template={`${home_page ? "home" : ""} template-page`}
        page={!home_page}
      />

      {/* <h1>{title.text}</h1> */}
      <div className={clsx("content", !home_page ? "md:px-md" : "")}>
        {slices}
        {/* <SlideContainer /> */}
      </div>
    </div>
  );
};

export default withPreview(Page);
