import React, { useContext } from "react";
import { Link } from "gatsby";
// import { useInView } from "react-intersection-observer";
import BgImg from "gatsby-background-image";
// import Img from "gatsby-image";
// import SliderImage from "../slices/SliderImage";
import { WrapperContext } from "../Layout";
import { linkResolver } from "../../core/utils";
import SlideVideo from "./SlideVideo";

const ProjetListItem = ({ input }) => {
  const { isMobile } = useContext(WrapperContext);
  // console.log(isMobile);
  // useEffect(() => {
  //   if (inView) {
  //     console.log("in view", input);
  //   }
  // }, [inView]);

  // const [ref, inView] = useInView({
  //   threshold: 0,
  //   delay: 100,
  //   trackVisibility: true,
  //   initialInView: true
  // });
  // console.log(input);
  if (!input) return null;
  const { image_featured, image_featured_mobile, video } = input.data;

  return (
    <article className='project-list-item w-screen h-screen'>
      {/* <Link to={linkResolver(input)}> */}
      <div className='inner h-screen w-screen'>
        {video && video.url ? (
          <SlideVideo
            className='h-full'
            input={{
              video: video.url,
              image: image_featured.url,
              autoplay: true,
            }}
          />
        ) : (
          <BgImg
            fluid={
              isMobile
                ? image_featured_mobile
                  ? image_featured_mobile.fluid
                  : image_featured.fluid
                : image_featured.fluid
            }
            style={{
              height: "100vh",
            }}
          />
        )}

        {/* <h2 className='absolute left-md bottom-md text-_lg md:text-lg blendable'>
            {title.text}
          </h2> */}
      </div>
      {/* </Link> */}
    </article>
  );
};

export default ProjetListItem;
