import React, { useState, useContext } from "react";
import SlickSlider from "react-slick";
import { Link } from "gatsby";
import { WrapperContext } from "../Layout";
import { linkResolver, _localizedSlug } from "../../core/utils";
import ProjetListItem from "./ProjetListItem";
import CursorPrevNextText from "../ui/CursorPrevNextText";

const ProjectSlider = ({ input }) => {
  // console.log(input);
  const data = input.items.filter((el) => el.project.document !== null);
  // console.log(data);

  const { isMobile } = useContext(WrapperContext);

  // const [h2, setH2] = useState(input[0].project.document.data.title.text);
  const [index, setIndex] = useState(0);

  const _sliderBeforeChange = (oldIndex, newIndex) => {
    // setH2(input[newIndex].project.document.data.title.text);
    setIndex(newIndex);
  };

  const settings = {
    // autoplay: !isMobile,
    dots: false,
    // speed: isMobile ? 250 : 500,
    speed: 0,
    swipeToSlide: true,
    slidesToScroll: 1,
    cssEase: isMobile ? "ease" : "cubic-bezier(0.53, 0, 0.36, 1)",
    beforeChange: _sliderBeforeChange,
    prevArrow: <CursorPrevNextText label='prev' left='0%' />,
    nextArrow: <CursorPrevNextText label='next' left='67%' />,
  };

  return (
    <div className={"slider "}>
      <SlickSlider {...settings}>
        {data.map(({ project }, i) => (
          <ProjetListItem key={i} input={project.document} />
          // <pre key={i}>{JSON.stringify(project.document)}</pre>
        ))}
      </SlickSlider>
      <h2 className='absolute left-md bottom-md text-_lg md:text-lg  z-50'>
        <Link to={_localizedSlug(data[index].project.document)}>
          {data[index].project.document.data.title.text}
        </Link>
      </h2>
    </div>
  );
};

export default ProjectSlider;
