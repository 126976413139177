import React, { useEffect, useContext } from "react";
// import { useInView } from "react-intersection-observer";
// import ProjetListItem from "./ProjetListItem";
import ProjectArticle from "../ProjectArticle";
// import SliderProjects from "./SliderProjects";
// import ScrollerProjects from "./ScrollerProjects";
// import { WrapperContext } from "../Layout";

const ProjetList = ({ input }) => {
  // console.log(input);
  // const { isMobile } = useContext(WrapperContext);

  // const projectsFeatured = input.items.filter((el) => el.featured);
  // const projectsNotFeatured = input.items.filter((el) => !el.featured);
  // console.log(projectsFeatured);
  // console.log(projectsNotFeatured);

  // const [ref, inView] = useInView({
  //   threshold: 0.2,
  //   // delay: 100,
  //   // trackVisibility: true
  //   // initialInView: true
  // });

  // useEffect(() => {
  //   console.log(inView);
  //   // PubSub.publish("PROJECTS_INVIEW", inView);
  //   if (inView) {
  //     document.querySelector("header .menu-toggle").classList.add("show");
  //   } else {
  //     document.querySelector("header .menu-toggle").classList.remove("show");
  //   }
  // }, [inView]);

  return (
    <section className='project-list'>
      {/* <div className='projects-featured mb-md md:mb-0'>
        {isMobile && <ScrollerProjects input={projectsFeatured} />}
        {!isMobile && <SliderProjects input={projectsFeatured} />}
      </div> */}
      <div id='projects' className='projects '>
        {input.items.map(({ project }, i) => (
          <ProjectArticle
            key={project.document.uid}
            input={project.document.data}
          />
        ))}
      </div>
    </section>
  );
};

export default ProjetList;
